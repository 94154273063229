<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Mobile Details</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="search-tab-outer-scroll" v-if="mobiledetailshowflag">
                    <div v-if="this.masterdata != ''">
                        <div v-if="prefillresponse">
                            <div class="voter-exit-box-outer mb-3" v-if="prefillresponse.data.personal_info.length != 0">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Personal Information</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Full Name</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                    prefillresponse.data.personal_info.full_name
                                                        ?
                                                        prefillresponse.data.personal_info.full_name
                                                        :
                                                        "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Date of Birth</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                    format_date(prefillresponse.data.personal_info.dob)
                                                }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Gender</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ prefillresponse.data.personal_info.gender ?
                                                    prefillresponse.data.personal_info.gender : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider type="dashed" class="mt-0 mb-2" />
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Age</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ prefillresponse.data.personal_info.age ?
                                                    prefillresponse.data.personal_info.age :
                                                    "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12"
                                                v-if="prefillresponse.data.personal_info.total_income != ''">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Total Income</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{
                                                    vueNumberFormat(prefillresponse.data.personal_info.total_income,
                                                        {
                                                            prefix: "₹ ",
                                                            decimal: ".",
                                                            thousand: ",",
                                                            precision: 0,
                                                            acceptNegative: true,
                                                        })
                                                }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Occupation</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ prefillresponse.data.personal_info.occupation ?
                                                    prefillresponse.data.personal_info.occupation
                                                    : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3" v-if="credit_scoreresponse">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group mb-0">
                                        <label class="individual-header-label">Credit Score</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <div class="from-label-value text-capitalize">
                                                    {{ credit_scoreresponse.data.credit_score ? credit_scoreresponse.data.credit_score : "N/A"
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="prefillresponse">
                            <div class="voter-exit-box-outer mb-3" v-if="prefillresponse.data.phone_info != null">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Contact Details</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <!-- <div class="row" v-for="msgperrgh in prefillresponse.data.phone_info" :key="msgperrgh">
                                            <div class="col"> -->
                                        <div class="row mb-2" v-for="(msgphone, index) in prefillresponse.data.phone_info"
                                            :key="msgphone"
                                            v-bind:class="{ dividerrowhide: index == prefillresponse.data.phone_info.length - 1 }">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Number</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ msgphone.number ? msgphone.number : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12" v-if="msgphone.reported_date">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Date</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ format_date(msgphone.reported_date) }}
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider class="mt-0 mb-2" />
                                            <!-- </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="voter-exit-box-outer mb-3" v-if="prefillresponse.data.email_info != null">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Email Address</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <!-- <div class="row" v-for="msgperrgh in ccr_response.cir_report_data_lst" :key="msgperrgh">
                                            <div class="col"> -->
                                        <div class="row mb-2" v-for="(msgemail, index) in prefillresponse.data.email_info"
                                            :key="msgemail"
                                            v-bind:class="{ dividerrowhide: index == prefillresponse.data.email_info.length - 1 }">
                                            <div class="col-lg-4 col-md-4 col-12" v-if="msgemail.email_address">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Email Address</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ msgemail.email_address ? msgemail.email_address : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider class="mt-0 mb-2" />
                                        </div>
                                        <!-- </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="voter-exit-box-outer mb-3" v-if="prefillresponse.data.address_info.length != 0">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Address</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <!-- <div class="row" v-for="msgperrgh in ccr_response.cir_report_data_lst" :key="msgperrgh">
                                            <div class="col"> -->
                                        <div class="row mb-2"
                                            v-for="(msgaddresszz, index) in prefillresponse.data.address_info"
                                            :key="msgaddresszz"
                                            v-bind:class="{ dividerrowhide: index == prefillresponse.data.address_info.length - 1 }">
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Address</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ msgaddresszz.address ? msgaddresszz.address : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">State</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ msgaddresszz.state ? msgaddresszz.state : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Postal</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ msgaddresszz.postal ? msgaddresszz.postal : "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12" v-if="msgaddresszz.reported_date">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Date</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ format_date(msgaddresszz.reported_date) }}
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider class="mt-0 mb-2" />
                                        </div>
                                        <!-- </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="voter-exit-box-outer mb-3"
                                v-if="prefillresponse.data.identity_info.pan_number.length != 0">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">PAN Card Details</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row"
                                            v-for="(msjee, index) in prefillresponse.data.identity_info.pan_number"
                                            :key="msjee"
                                            v-bind:class="{ dividerrowhide: index == prefillresponse.data.identity_info.pan_number.length - 1 }">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <div class="from-label-value text-capitalize">
                                                        {{ msjee.id_number ? msjee.id_number : "N/A"
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="voter-exit-box-outer mb-3"
                                v-if="prefillresponse.data.identity_info.voter_id.length != 0">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Voter Id Number</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row"
                                            v-for="(msjeell, index) in prefillresponse.data.identity_info.voter_id"
                                            :key="msjeell"
                                            v-bind:class="{ dividerrowhide: index == prefillresponse.data.identity_info.voter_id.length - 1 }">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Id Number</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ msjeell.id_number ?
                                                    msjeell.id_number :
                                                    "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="voter-exit-box-outer mb-3"
                                v-if="prefillresponse.data.identity_info.aadhaar_number.length != 0">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Aadhaar Number</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row"
                                            v-for="(aadharmsjeell, index) in prefillresponse.data.identity_info.aadhaar_number"
                                            :key="aadharmsjeell"
                                            v-bind:class="{ dividerrowhide: index == prefillresponse.data.identity_info.aadhaar_number.length - 1 }">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <div class="from-label-value text-capitalize">
                                                        {{ aadharmsjeell.id_number ?
                                                    aadharmsjeell.id_number :
                                                    "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="voter-exit-box-outer mb-3"
                                v-if="prefillresponse.data.identity_info.driving_license.length != 0">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Driving License Number</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row"
                                            v-for="(drivingrmsjeell, index) in prefillresponse.data.identity_info.driving_license"
                                            :key="drivingrmsjeell"
                                            v-bind:class="{ dividerrowhide: index == prefillresponse.data.identity_info.driving_license.length - 1 }">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Id Number</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ drivingrmsjeell.id_number ?
                                                    drivingrmsjeell.id_number :
                                                    "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="voter-exit-box-outer mb-3"
                                v-if="prefillresponse.data.identity_info.passport_number.length != 0">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Passport Number</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row"
                                            v-for="(passportrmsjeell, index) in prefillresponse.data.identity_info.passport_number"
                                            :key="passportrmsjeell"
                                            v-bind:class="{ dividerrowhide: index == prefillresponse.data.identity_info.passport_number.length - 1 }">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Id Number</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ passportrmsjeell.id_number ?
                                                    passportrmsjeell.id_number :
                                                    "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="voter-exit-box-outer mb-3"
                                v-if="prefillresponse.data.identity_info.ration_card.length != 0">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Ration Card Number</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row"
                                            v-for="(rationrmsjeell, index) in prefillresponse.data.identity_info.ration_card"
                                            :key="rationrmsjeell"
                                            v-bind:class="{ dividerrowhide: index == prefillresponse.data.identity_info.ration_card.length - 1 }">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Id Number</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ rationrmsjeell.id_number ?
                                                    rationrmsjeell.id_number :
                                                    "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="voter-exit-box-outer mb-3"
                                v-if="prefillresponse.data.identity_info.other_id.length != 0">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="custom-form-group">
                                            <label class="individual-header-label">Other Id's</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="row"
                                            v-for="(otherrmsjeell, index) in prefillresponse.data.identity_info.other_id"
                                            :key="otherrmsjeell"
                                            v-bind:class="{ dividerrowhide: index == prefillresponse.data.identity_info.other_id.length - 1 }">
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <div class="customer-label-group mb-2">
                                                    <label class="form-label">Id Number</label>
                                                    <div class="from-label-value text-capitalize">
                                                        {{ otherrmsjeell.id_number ?
                                                    otherrmsjeell.id_number :
                                                    "N/A" }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3" v-if="uaninforesponse">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">UAN - Universal Account Number allotted
                                            by Employees’ Fund Organization (EPFO)</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">UAN</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ uaninforesponse.data.pf_uan ? uaninforesponse.data.pf_uan : "N/A"
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="voter-exit-box-outer mb-3" v-if="upidataresponse">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">UPI Id</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Full Name</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ upidataresponse.data.full_name ? upidataresponse.data.full_name :
                                                "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Mobile Number</label>
                                                <div class="from-label-value" v-if="upidataresponse.data.mobile_number">
                                                    +91-{{ upidataresponse.data.mobile_number }}
                                                </div>
                                                <div class="from-label-value" v-else>
                                                    N/A
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">UPI Id</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ upidataresponse.data.upi_id ? upidataresponse.data.upi_id : "N/A"
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="uanhistoryresponse">
                        <div class="voter-exit-box-outer mb-3"
                            v-if="uanhistoryresponse.data.employment_history.length != 0">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="individual-header-label">Employment History</label>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="row"
                                        v-for="(emphistory, index) in uanhistoryresponse.data.employment_history"
                                        :key="emphistory"
                                        v-bind:class="{ dividerrowhide: index == uanhistoryresponse.data.employment_history.length - 1 }">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Date of Joining</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ format_date(emphistory.date_of_joining) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Date of Exit</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ format_date(emphistory.date_of_exit) }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Employer Name</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ emphistory.establishment_name ? emphistory.establishment_name :
                                                "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Guardian Name</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ emphistory.guardian_name ? emphistory.guardian_name : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Member Id</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ emphistory.member_id ? emphistory.member_id : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="customer-label-group mb-2">
                                                <label class="form-label">Name</label>
                                                <div class="from-label-value text-capitalize">
                                                    {{ emphistory.name ? emphistory.name : "N/A" }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="voter-exit-box-outer pb-3" v-if="this.masterdata == ''">
                        <div>No records found.</div>
                    </div>
                </div>
                <div class="voter-exit-box-outer pb-3" v-if="errormessagedisplay">
                    <div  class="text-danger">{{errormessage}}</div>
                </div>
                <div class="voter-exit-box-outer pb-3" v-if="norecordsmessagedisplay">
                    <div>No records found.</div>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
import ApiService from "../service/ApiService";
  import { useRoute } from "vue-router";
  import moment from 'moment';
  export default {
    data() {
        return {
            countrycode: { value: 101, label: "+91" },
            countryCodeList: [],
            searchmobile: "",
            btnloader: false,
            disabledinput: false,
            errormessagedisplay:false,
            mobiledetailshowflag: false,
            uanhistoryresponse: '',
            inquiry_request_info: '',
            ccr_response: '',
            identity_info_email_address_info: [],
            id_and_contact_info: '',
            nodatafoundflag: false,
            localdatafound: false,
            localbtnloader: false,
            upidataresponse: '',
            prefillresponse: '',
            uaninforesponse: '',
            credit_scoreresponse:'',
            firstname: '',
            errormessage:'',
            masterdata: '',
            norecordsmessagedisplay: false,
            prefillresponsevalue: '',
            clearbtnflag: '',
        };
    },
    ApiService: null,
    created() {
      this.ApiService = new ApiService();
    },
  
    mounted() {
      const route = useRoute();
      this.routeParam = route.params.mobileId;
      this.loading = true;
      this.getmobileresponse({ mobile_number: this.routeParam });
    },
    methods: {
      getmobileresponse(item) {
        this.ApiService.getmobileresponse(item).then((data) => {
            if (data.success == true) {
                if (data.datastatus == 1) {
                    this.localdatafound = true;
                } else {
                    this.localdatafound = false;
                }
                this.btnloader = false;
                this.masterdata = data.records;
                if (this.masterdata != '') {
                    if(data.records.employement_uan_response) {
                        let checkemployement = JSON.parse(data.records.employement_uan_response);
                        if(checkemployement.success == true) {
                            this.uanhistoryresponse = JSON.parse(data.records.employement_uan_response);
                        } else {
                            this.uanhistoryresponse = '';
                        }
                    }

                    if(data.records.name_details_response) {
                        let checknamedetails = JSON.parse(data.records.name_details_response);
                        if(checknamedetails.success == true) {
                            this.upidataresponse = JSON.parse(data.records.name_details_response);
                        } else {
                            this.upidataresponse = '';
                        }
                    }
                    if(data.records.prefill_Details) {
                        let checkprefill = JSON.parse(data.records.prefill_Details);
                        if(checkprefill.success == false) {
                            this.errormessagedisplay = false;
                            this.norecordsmessagedisplay = true;
                        } else {
                            this.prefillresponse = JSON.parse(data.records.prefill_Details);
                            this.norecordsmessagedisplay = false;
                        }
                    }
                    
                    if(data.records.uan_details_response) {
                        let checkunadetail = JSON.parse(data.records.uan_details_response);
                        if(checkunadetail.success == true) {
                            this.uaninforesponse = JSON.parse(data.records.uan_details_response);
                        } else {
                            this.uaninforesponse = ''; 
                        }
                    }
                    if(data.records.credit_score_response) {
                        let checkcreditscrore = JSON.parse(data.records.credit_score_response);
                        if(checkcreditscrore.success == true) {
                            this.credit_scoreresponse = JSON.parse(data.records.credit_score_response);
                        } else {
                            this.credit_scoreresponse = ''; 
                        }
                    }
                }

                this.mobiledetailshowflag = true;
                this.disabledinput = true;
                this.errormessagedisplay = false;
                this.clearbtnflag = true;
            } else {
                this.btnloader = false;
                this.mobiledetailshowflag = false;
                this.errormessage = data.message;
                this.errormessagedisplay = true;
                this.clearbtnflag = false;
            }
        });
      },
      onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("formSearchMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        isLetterWithOutSpace(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z]+$/.test(char)) return true;
            else e.preventDefault();
        },
    },
  };
  </script>
  <style scoped>
  .form-box-outer {
      padding: 16px 16px;
      margin-bottom: 15px;
      border: 1px solid #e0e5ed;
      border-radius: 0px 0px 4px 4px;
  }
  
  .search-voter-section-outer {
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
  }
  
  .search-voter-section-outer::-webkit-scrollbar {
      display: block;
  }
  
  .search-voter-section-outer::-webkit-scrollbar {
      width: 0.3em;
      height: 0.3em;
  }
  
  .search-voter-section-outer::-webkit-scrollbar-thumb {
      background-color: rgb(113 115 119 / 49%);
      border-radius: 3px;
  }
  
  .search-tab-outer-scroll {
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
  }
  
  .search-tab-outer-scroll::-webkit-scrollbar {
      display: block;
  }
  
  .search-tab-outer-scroll::-webkit-scrollbar {
      width: 0.3em;
      height: 0.3em;
  }
  
  .search-tab-outer-scroll::-webkit-scrollbar-thumb {
      background-color: rgb(113 115 119 / 49%);
      border-radius: 3px;
  }
  
  .voter-exit-box-outer {
      padding: 16px 16px 0;
      background: #e4f8f0 0% 0% no-repeat padding-box;
      border: 1px solid #bbd9cc;
  }
  
  .customer-label-group {
      margin-bottom: 20px;
  }
  
  .customer-label-group .form-label {
      font-family: "AcuminPro-Regular";
      font-size: 13px;
      letter-spacing: 0px;
      color: #4a5463;
      line-height: 13px;
      margin-bottom: 6px;
  }
  
  .customer-label-group .from-label-value {
      font-family: "AcuminPro-SemiBold";
      font-size: 14px;
      letter-spacing: 0.28px;
      color: #2b2e38;
      line-height: 20px;
  }
  
  .individual-header-label {
      font-family: 'AcuminPro-SemiBold';
      font-size: 13px;
      text-align: left;
      letter-spacing: 0.52px;
      color: #0d488b;
      line-height: 15px;
  }
  
  .dividerrowhide .p-divider {
      display: none;
  }
  
  .form-submit-btn.btn {
      background: #ef8080 0% 0% no-repeat padding-box;
      border-color: #de2525;
      border-radius: 4px;
      letter-spacing: 0.26px;
      color: #ffffff;
      font-size: 13px;
      line-height: 20px;
      padding: 6px 15px;
      font-family: "AcuminPro-Regular";
      min-width: 110px;
  }
  
  .form-submit-btn:focus {
      box-shadow: none;
  }
  </style>